import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";

import { push } from "connected-react-router";
import IconButton from "@material-ui/core/IconButton";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";

import { NavLink } from "react-router-dom";

import MenuAppBar from "layouts/MenuAppBar/MenuAppBar";

import privateRoutes from "routes/private";
import { switchRoutes } from "helpers/routes";

// This file is shared across the demos.
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import AssignmentIcon from "@material-ui/icons/Assignment";
import PeopleIcon from "@material-ui/icons/People";
import PeopleOutlineIcon from "@material-ui/icons/PeopleOutline";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd"; 
import { ImmutableLoadingBar as LoadingBar } from "react-redux-loading-bar";

const drawerWidth = 75;

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  profile: {
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    height: "64px"
  },
  appFrame: {
    zIndex: 1,
    //overflow: "hidden",
    position: "relative",
    display: "flex",
    width: "100%"
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`
  },
  "appBar-left": {
    marginLeft: drawerWidth
  },
  "appBar-right": {
    marginRight: drawerWidth
  },
  drawerPaper: {
    position: "relative",
    overflow: "hidden",
    width: drawerWidth
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: "80px 15px 15px 15px",
    //overflow: "auto"
	//minWidth: "1200px"
  },
  iconDiv: {
    "&:hover": {
      background: "white",
      opacity: 1,
      borderRight: "4px solid #FFBA00"
    }
  },
  icon: {
    color: "#B2B2B2",
    "&:hover": {
      color: "#FFBA00"
    }
  },

  activeNavLink: {
    "& > div": {
      background: "white",
      opacity: 1,
      borderRight: "4px solid #FFBA00"
    },
    "& svg": {
      color: "#FFBA00"
    }
  }
});

class PermanentDrawer extends React.Component {
  state = {
    anchorEl: null,
    selectedApplication: true,
    selectedCustomer: false
  };

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleLogout = () => {
    const { dispatch } = this.props;
    dispatch(push("/login"));
  };

  goToProfile = () => {
    const { dispatch } = this.props;
    dispatch(push("/admin/profile"));
  };

  render() {
    const { classes } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    return (
      <div className={classes.root}>
        <div className={classes.appFrame}>
          <MenuAppBar />
          <Drawer
            variant="permanent"
            classes={{
              paper: classes.drawerPaper
            }}
          >
            <div className={classes.toolbar}>
              <div className={classes.profile}>
                <IconButton
                  aria-owns={open ? "menu-appbar" : null}
                  aria-haspopup="true"
                  onClick={this.handleMenu}
                  color="inherit"
                >
                  <LoadingBar
                    style={{
                      backgroundColor: "#6F6F6F",
                      height: "3px",
                      position: "fixed",
                      top: "0",
                      left: 0,
                      right: 0
                    }}
                  />
                  <AccountCircle />
                </IconButton>
              </div>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right"
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right"
                }}
                open={open}
                onClose={this.handleClose}
              >
                <MenuItem onClick={this.goToProfile}>Profile</MenuItem>
                <MenuItem onClick={this.handleLogout}>Logout</MenuItem>
              </Menu>
            </div>
            <Divider />
            <List>
              <NavLink
                to="/admin/applications"
                activeClassName={classes.activeNavLink}
              >
                <ListItem button className={classes.iconDiv}>
                  <ListItemIcon>
                    <AssignmentIcon className={classes.icon} />
                  </ListItemIcon>
                </ListItem>
              </NavLink>
              <NavLink
                to="/admin/customers"
                activeClassName={classes.activeNavLink}
              >
                <ListItem button className={classes.iconDiv}>
                  <ListItemIcon>
                    <PeopleIcon className={classes.icon} />
                  </ListItemIcon>
                </ListItem>
              </NavLink>
              <NavLink
                to="/admin/leads"
                activeClassName={classes.activeNavLink}
              >
                <ListItem button className={classes.iconDiv}>
                  <ListItemIcon>
                    <PeopleOutlineIcon className={classes.icon} />
                  </ListItemIcon>
                </ListItem>
              </NavLink>
			  <NavLink
                to="/admin/login-log"
                activeClassName={classes.activeNavLink}
              >
                <ListItem button className={classes.iconDiv}>
                  <ListItemIcon>
                    <AssignmentIndIcon className={classes.icon} />
                  </ListItemIcon>
                </ListItem>
              </NavLink>
            </List>
          </Drawer>
          <main className={classes.content}>{switchRoutes(privateRoutes)}</main>
        </div>
      </div>
    );
  }
}

PermanentDrawer.propTypes = {
  classes: PropTypes.object.isRequired
};

export default connect()(withStyles(styles)(PermanentDrawer));
